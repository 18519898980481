"use client";

import { createContext, useContext } from "react";
import { Clock } from "lucide-react";
import Img from "next/image";
import { HandShakeIcon } from "@/components/icons/handshake-icon";
import { ItemRarity, ItemType, ItemSubType } from "@/types";
import { Header } from "./components/header";
import { Details } from "./components/details";
import { List } from "./components/list";

import { cn } from "@repo/ui/utils";

interface ItemCardContextType {
  rarity: ItemRarity;
  type: ItemType;
  subType: ItemSubType;
}

const ItemCardContext = createContext({} as ItemCardContextType);

export function ItemCard({
  rarity,
  type,
  subType,
  className,
  children,
}: React.HTMLAttributes<HTMLDivElement> & {
  rarity: ItemRarity;
  type: ItemType;
  subType: ItemSubType;
}) {
  const borderImage: Record<ItemRarity, React.CSSProperties> = {
    magic: {
      borderImage: "url('/assets/card/magic-border.png')",
    },
    rare: {
      borderImage: "url('/assets/card/rare-border.png')",
    },
    legendary: {
      borderImage: "url('/assets/card/legendary-border.png')",
    },
    unique: {
      borderImage: "url('/assets/card/unique-border.png')",
    },
  };

  return (
    <ItemCardContext.Provider value={{ rarity, type, subType }}>
      <div
        className={cn(
          "relative -m-1 grid min-h-[380px] w-[251px] grid-rows-[auto_1fr_auto] gap-2",
          className,
        )}
        style={{
          ...borderImage[rarity],
          borderImageWidth: "auto",
          borderImageSlice: "180 310 160 320",
        }}
      >
        {children}
      </div>
    </ItemCardContext.Provider>
  );
}

ItemCard.Header = Header;
ItemCard.Body = Body;
ItemCard.Footer = Footer;

ItemCard.Details = Details;
ItemCard.Image = Image;
ItemCard.Title = Title;
ItemCard.Icon = Icon;
ItemCard.Text1 = Text1;
ItemCard.ListedAt = ListedAt;
ItemCard.User = User;
ItemCard.List = List;
ItemCard.Separator = Separator;

function Body({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="px-[7%]">{children}</div>;
}

function Footer({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="px-[7%] pb-3">{children}</div>;
}

function Image() {
  const { type, subType } = useContext(ItemCardContext);
  return (
    <div className="absolute right-[6%] top-[15%]">
      <div className="relative h-[72px] w-[60px]">
        <Img alt="" fill src={`/assets/${type}/${subType}.webp`} />
      </div>
    </div>
  );
}

function Title({ children }: React.PropsWithChildren): JSX.Element {
  const { rarity } = useContext(ItemCardContext);

  return (
    <h2
      className={cn(" font-diablo text-sm", {
        "text-legendary": rarity === "legendary",
        "text-unique": rarity === "unique",
        "text-rare": rarity === "rare",
        "text-magic": rarity === "magic",
      })}
    >
      {children}
    </h2>
  );
}

function Icon() {
  return (
    <div>
      <p className="flex h-[15px] w-[30px] items-center justify-between border border-green-200 bg-green-200/20 px-1 text-[11px] text-green-200">
        <HandShakeIcon className="h-3 w-3" />7
      </p>
    </div>
  );
}

function Text1({ children }: React.HTMLAttributes<HTMLParagraphElement>) {
  return <p className="mb-1 font-diablo text-xs  text-gray-100">{children}</p>;
}

function User({ children }: React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p className="flex items-center font-diablo text-xs text-gray-50">
      <i className="mr-1 block h-[10px] w-[10px] rounded-full bg-green-100" />
      {children}
    </p>
  );
}

function ListedAt({ datetime }: { datetime: string }) {
  return (
    <p className=" flex items-center font-diablo text-xs text-gray-50">
      <Clock className="mr-1 h-[10px] w-[10px]" />
      {datetime}
      Listed 4 hours ago
    </p>
  );
}

function Separator({ className }: { className?: string }): JSX.Element {
  return (
    <hr
      className={cn(
        "my-1 h-1 border-none bg-[image:url('/images/separators/separator.png')] bg-contain bg-no-repeat",
        className,
      )}
    />
  );
}
