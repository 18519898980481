export function ParallelogramIcon({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 10 11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="#555555"
        height="6.0962"
        stroke="#6A6A6A"
        strokeWidth="0.5"
        transform="rotate(-45 0.853553 5.5)"
        width="6.0962"
        x="0.853553"
        y="5.5"
      />
    </svg>
  );
}
