export function Header({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="relative grid gap-2 pl-[8%] pr-[75px] pt-4">{children}</div>
  );
}

Header.Bottom = Bottom;
Header.BottomLeft = BottomLeft;
Header.BottomRight = BottomRight;

function Bottom({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="grid grid-cols-[1fr_auto]">{children}</div>;
}

function BottomLeft({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="grid gap-1">{children}</div>;
}

function BottomRight({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className="grid gap-1">{children}</div>;
}
