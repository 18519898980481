export function StarIcon({ className }: { className?: string }): JSX.Element {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 9 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.02447 0.463525C4.17415 0.00286961 4.82585 0.00286984 4.97553 0.463525L5.51031 2.10942C5.57725 2.31543 5.76923 2.45492 5.98584 2.45492H7.71644C8.2008 2.45492 8.40219 3.07472 8.01033 3.35942L6.61025 4.37664C6.43501 4.50397 6.36168 4.72965 6.42861 4.93566L6.9634 6.58156C7.11307 7.04222 6.58583 7.42528 6.19398 7.14058L4.79389 6.12336C4.61865 5.99603 4.38135 5.99603 4.20611 6.12336L2.80602 7.14058C2.41417 7.42528 1.88693 7.04222 2.0366 6.58156L2.57139 4.93566C2.63832 4.72965 2.56499 4.50397 2.38975 4.37664L0.989666 3.35942C0.59781 3.07472 0.799197 2.45492 1.28356 2.45492H3.01416C3.23077 2.45492 3.42275 2.31543 3.48969 2.10942L4.02447 0.463525Z"
        fill="currentColor"
      />
    </svg>
  );
}
