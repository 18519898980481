export function HandShakeIcon({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 14 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4201 0.500041H10.8202C10.5808 0.497708 10.352 0.593949 10.1936 0.763677C10.0352 0.933407 9.96288 1.15971 9.99566 1.38311L7.44216 0.958477C7.12669 0.907149 6.80442 0.999307 6.57244 1.20754L6.03984 1.6864L3.94478 1.57616L3.96272 1.52017H3.96334C4.04561 1.28337 4.00293 1.02323 3.84767 0.821407C3.6924 0.619589 3.44435 0.50003 3.17959 0.50003H0.579698C0.397841 0.50003 0.25 0.638849 0.25 0.81091V6.34209C0.25 6.42434 0.28464 6.50366 0.346499 6.562C0.408358 6.62033 0.492485 6.65299 0.579711 6.65299H1.58429C1.94121 6.65299 2.25731 6.43601 2.36803 6.11637L2.69773 5.18313L6.26945 7.79918C6.61029 8.05057 7.0835 8.06749 7.44353 7.84118L10.5136 5.92214L11.3728 5.37621L11.632 6.11638C11.7433 6.43542 12.0594 6.65123 12.4157 6.65123H13.4203C13.5075 6.65123 13.5916 6.61857 13.6535 6.56024C13.7154 6.50191 13.75 6.42259 13.75 6.34034V0.810947C13.75 0.728706 13.7154 0.649385 13.6535 0.591046C13.5916 0.532708 13.5075 0.500055 13.4203 0.500055L13.4201 0.500041ZM7.07905 7.32313C6.95472 7.40129 6.7908 7.39487 6.67326 7.30796L2.98831 4.60277C2.96357 4.58527 2.93635 4.57127 2.90728 4.56077L3.73184 2.18572H3.76462L5.38588 2.27146L5.16999 2.46569H5.17061C4.7605 2.82149 4.73389 3.42343 5.11122 3.80956C5.48794 4.19626 6.12632 4.22135 6.53643 3.86555L7.21253 3.29628L9.80372 5.61996L7.07905 7.32313ZM13.0898 6.0306H12.4155C12.3407 6.0341 12.2733 5.99035 12.2504 5.92328L10.6636 1.32593C10.6469 1.2781 10.6556 1.22619 10.6865 1.18594C10.7175 1.1457 10.7676 1.12178 10.8201 1.12178H13.0897L13.0898 6.0306Z"
        fill="#0F9C7A"
      />
    </svg>
  );
}
